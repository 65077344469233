import { Donation } from 'types/Cart';
import { Location } from 'types/Location';
import { MyAccountOrderEntry } from 'types/MyAccount';
import { OrderEntry } from 'types/Order';
import { PcConfiguration } from 'types/PcConfiguration';
import { Product } from 'types/Product';

export const createLocationObject = (locationObject: Location, country?: string) => {
  const locationCountry: Location = locationObject?.country || { country: { name: country } };
  const location: Location = {
    ...(locationCountry && { country: locationCountry }),
    name: locationObject?.town,
    postalCode: locationObject?.postalCode,
  };

  return location;
};

export const getOrderEntries = (
  orderEntries: OrderEntry[],
  orderDonations?: Donation[],
  orderPcConfigurations?: PcConfiguration[],
  isOrderDetail?: boolean,
) => {
  // Donations
  const selectedOrderDonations = orderDonations?.filter((donation) => donation?.selected) || [];
  const showDescription = isOrderDetail || selectedOrderDonations?.length <= 1;
  const donations: MyAccountOrderEntry[] = selectedOrderDonations?.map((donation) => ({
    ...(showDescription && { description: donation?.displayName }),
    imageUrl: donation?.image?.url ?? '',
    totalPriceValue: donation?.price?.value ?? 0,
    type: 'DONATION',
  }));

  // Evouchers
  const hasMultipleEvouchers = orderEntries?.filter((entry) => !!entry?.evoucherRecipientMail)?.length > 1;

  // Pc Cofnigurators
  const pcConfigurators: MyAccountOrderEntry[] =
    orderPcConfigurations?.map((pcConfig) => {
      const imageUrl = !isOrderDetail
        ? pcConfig.parts?.find((part) => part?.product)?.product?.images?.[0]?.url
        : pcConfig?.image?.url;
      const selectedProducts =
        pcConfig?.parts?.reduce((prev: Product[], curr) => {
          if (curr?.product) {
            return [...prev, curr?.product];
          }

          return prev;
        }, []) ?? [];

      return {
        description: pcConfig?.uid,
        imageUrl: imageUrl ?? '',
        pcConfigurationProducts: selectedProducts,
        totalPriceValue: pcConfig?.totalPrice?.value ?? 0,
        type: 'PC_CONFIGURATION',
      };
    }) || [];

  // Default entries incl. e-vouchers
  const filteredDefaultEntries = orderEntries?.filter((entry) => !entry?.pcConfigurationCode) || [];
  const defaultEntries: MyAccountOrderEntry[] = filteredDefaultEntries?.map((entry) => {
    const sharedProps = {
      imageUrl: entry?.product?.images?.[0]?.url ?? '',
      statusDisplay: entry?.statusDisplay,
      title: entry?.product?.brand?.name,
      totalPriceValue: entry?.totalPrice?.value ?? 0,
    };

    if (
      entry?.evoucherRecipientMail ||
      entry?.evoucherMessage ||
      Object.values(entry?.evoucherValue || {}).length > 0
    ) {
      return {
        ...sharedProps,
        evoucherRecipientMail: entry?.evoucherRecipientMail,
        type: 'EVOUCHER',
        ...(!isOrderDetail &&
          hasMultipleEvouchers && {
            evoucherRecipientMail: undefined,
          }),
      };
    } else {
      return {
        ...sharedProps,
        code: entry?.product?.code,
        contractKey: entry?.contractKey,
        description: entry?.product?.name,
        manualUrl: entry?.product?.manual?.url,
        type: 'DEFAULT',
      };
    }
  });

  const products: MyAccountOrderEntry[] = [...defaultEntries, ...pcConfigurators, ...donations];

  return products || [];
};

export const getPcConfigurators = (entries: OrderEntry[]) => {
  const pcConfigurations: Record<string, { products: OrderEntry[] }> = {};

  entries.forEach((entry) => {
    if (entry?.pcConfigurationCode && !!entry?.product?.partners?.length) {
      if (pcConfigurations[entry.pcConfigurationCode]?.products) {
        pcConfigurations[entry.pcConfigurationCode].products.push(entry);
      } else {
        pcConfigurations[entry.pcConfigurationCode] = {
          products: [entry],
        };
      }
    }
  });

  return pcConfigurations;
};

export const getOrderEntryTitle = (entry: MyAccountOrderEntry) => {
  switch (entry?.type) {
    case 'DONATION':
      return 'my_account_order_donation';
    case 'EVOUCHER':
      return 'my_account_order_evoucher';
    case 'PC_CONFIGURATION':
      return 'my_account_order_pc_configuration';
    default:
      return null;
  }
};

export const getOrderEntryDescription = (entry: MyAccountOrderEntry) => {
  switch (entry?.type) {
    case 'EVOUCHER':
      return 'my_account_order_evoucher';
    default:
      return null;
  }
};

import { ParsedUrlQuery } from 'querystring';
import { Brand } from 'types/Brand';
import { CMSLeaderBoard } from 'types/CMS';
import { CategoryContentTile } from 'types/Category';
import { FlagshipPlacement } from 'types/Criteo';
import { Image } from 'types/Image';
import { Media } from 'types/Media';
import { Product, ProductVideo } from 'types/Product';
import { Promotion, SimplePromotion, SupplierPromotion } from 'types/Promotion';
import { v4 as uuidv4 } from 'uuid';
import { ATP_MESSAGES } from '../constants/atp';
import {
  MEDIA_TYPES,
  PLP_CONTENT_TYPES,
  PRODUCT_PARTNERS,
  PRODUCT_STOCK_MESSAGE,
  PlpSearchResult,
} from '../constants/product';
import { getYoutubeThumbnailUrl } from './mediaUtil';
import { nonNullable } from './typeUtil';

export const getRealTimeInfoForProduct = (realTimeInfo: Product[], productCode: string) => {
  if (realTimeInfo?.length) {
    return realTimeInfo.find((info) => info.code === productCode);
  }
  return null;
};

export const createImageObject = (media: Image) => ({
  id: uuidv4(),
  media,
  type: MEDIA_TYPES.IMAGE,
});

export const createVideoObject = (media: Media) => ({
  id: uuidv4(),
  media,
  type: MEDIA_TYPES.VIDEO,
});

export const createYoutubeObject = (media: ProductVideo) => {
  const thumbnailUrl = getYoutubeThumbnailUrl(media.url);

  if (!thumbnailUrl) {
    return null;
  }

  return {
    id: uuidv4(),
    media: {
      ...media,
      thumbs: {
        default: {
          url: thumbnailUrl,
        },
      },
    },
    type: MEDIA_TYPES.YOUTUBE,
    url: media.url,
  };
};

export function getProductMediaList(images: Image[] = [], videos: Media[] = [], youtubeVideos: ProductVideo[] = []) {
  const initialImage = createImageObject(images[0]);
  const mappedVideos = videos?.map((video) => createVideoObject(video)) || [];
  const mappedYoutubeVideos = youtubeVideos?.map((video) => createYoutubeObject(video))?.filter((obj) => !!obj) || [];
  const mappedImages = images.slice(1, images.length)?.map((image) => createImageObject(image)) || [];

  return [initialImage, ...mappedYoutubeVideos, ...mappedVideos, ...mappedImages];
}

const mapPotentialPromotion = ({ code, description, name }: Promotion): SimplePromotion => ({
  code,
  ...(description && { description }),
  ...(name && { label: name }),
});

const mapPotentialPromotions = (potentialPromotions: Promotion[]): SimplePromotion[] =>
  potentialPromotions
    .filter(
      ({ enabled, endDate, startDate }) =>
        enabled && (!startDate || new Date() >= new Date(startDate)) && (!endDate || new Date() <= new Date(endDate)),
    )
    .map(mapPotentialPromotion);

const mapSupplierPromotion = ({
  code,
  description,
  label,
  logo,
  pdf,
  pdfButtonText,
  price,
  supplierLink,
  supplierLinkButtonText,
  supplierPromotionGroup,
}: SupplierPromotion): SimplePromotion => ({
  code,
  ...(description && { description }),
  ...(label && { label }),
  ...(logo && { logo }),
  ...(price && { price }),
  ...(supplierPromotionGroup?.endDate && { expiryDate: supplierPromotionGroup.endDate }),
  ...(supplierLink && supplierLinkButtonText && { cta: { href: supplierLink, label: supplierLinkButtonText } }),
  ...(pdf && pdfButtonText && { pdfCta: { href: pdf.url, label: pdfButtonText } }),
});

const mapSupplierPromotions = (supplierPromotions: SupplierPromotion[]): SimplePromotion[] =>
  supplierPromotions.map(mapSupplierPromotion);

export const getProductPromotions = (product: Product) => [
  ...mapPotentialPromotions(product.potentialPromotions ?? []),
  ...mapSupplierPromotions(product.supplierPromotions ?? []),
];

interface EnrichSearchResultsParams {
  contentTiles?: CategoryContentTile[];
  flagship?: FlagshipPlacement;
  leaderBoardPosition?: number;
  leaderBoards?: CMSLeaderBoard[];
  products?: Product[];
  promotedBrands?: Brand[];
  promotedBrandsPosition?: number;
}

export function enrichSearchResults({
  contentTiles = [],
  flagship,
  leaderBoards = [],
  products = [],
  promotedBrands = [],
}: EnrichSearchResultsParams) {
  if (!products.length) return [];
  const searchResults: PlpSearchResult[] = products.map((product) => ({
    ...product,
    type: PLP_CONTENT_TYPES.PRODUCT,
  }));

  const hasContentTiles = !!contentTiles?.length;
  const hasFlagship = !!flagship;
  const hasLeaderBoards = !!leaderBoards?.length;
  const hasPromotedBrands = !!promotedBrands?.length;

  // Order special slots, default mobile order is every two products
  // On desktop we use css grid to order the products
  const specialSlots = [
    hasContentTiles ? 'contentTile' : undefined,
    hasFlagship ? 'flagship' : undefined,
    hasLeaderBoards ? 'leaderBoards' : undefined,
    hasPromotedBrands ? 'promotedBrands' : undefined,
  ].filter(nonNullable);
  let firstSlotIndex = 2;
  specialSlots.forEach((slot, index) => {
    const newIndex = firstSlotIndex + index * 2;

    if (slot === 'contentTile' && hasContentTiles) {
      const contentTile = contentTiles[0];
      searchResults.splice(newIndex, 0, { ...contentTile, type: PLP_CONTENT_TYPES.CONTENT_TILE });
      firstSlotIndex++;
      return;
    }
    // Dont append when previous item is not a product
    if (newIndex - 1 > searchResults.length) {
      return;
    }

    if (slot === 'flagship' && hasFlagship) {
      searchResults.splice(newIndex, 0, { ...flagship, type: PLP_CONTENT_TYPES.FLAGSHIP });
      firstSlotIndex++;
      return;
    }

    if (slot === 'leaderBoards' && hasLeaderBoards) {
      searchResults.splice(newIndex, 0, { leaderBoards, type: PLP_CONTENT_TYPES.LEADER_BOARD });
      firstSlotIndex++;
      return;
    }

    if (slot === 'promotedBrands' && hasPromotedBrands) {
      searchResults.splice(newIndex, 0, { type: PLP_CONTENT_TYPES.PROMOTED_BRANDS });
      firstSlotIndex++;
      return;
    }
  });

  return searchResults;
}

/**
 * Function which formats the html product description
 * @param {string} productDescription - the product description
 * @return {string} the formatted product description
 */
export function formatProductDescription(productDescription: string) {
  let description;

  if (productDescription) {
    const tagRegEx = new RegExp(/(<([^>]+)>)/gi);
    const newLineRegEx = new RegExp(/\r?\n|\r/gi);
    const htmlEntitiesRegEx = new RegExp(/&(nbsp|amp|quot|lt|gt);/g);
    const quoteRegEx = new RegExp(/&(#39);/g);
    const spacesRegEx = new RegExp(/\s{2,}/g);
    description = productDescription
      .replace(tagRegEx, '')
      .replace(newLineRegEx, ' ')
      .replace(htmlEntitiesRegEx, ' ')
      .replace(quoteRegEx, "'")
      .replace(spacesRegEx, ' ');
  }

  return description;
}

export const getProductCodeFromQuery = (query: ParsedUrlQuery) => {
  const id = Array.isArray(query.id) ? query.id[0] : query.id;
  return id?.split('-')[0].toString() ?? '';
};

const NOT_IN_STOCK_STOCKMESSAGES = [
  PRODUCT_STOCK_MESSAGE.NO_STOCK_TEMP,
  PRODUCT_STOCK_MESSAGE.ON_ORDER,
  PRODUCT_STOCK_MESSAGE.PRE_ORDER,
];

export const checkProductInStock = (product: Product) =>
  !NOT_IN_STOCK_STOCKMESSAGES.includes(product?.atp?.stockMessage ?? '');

export const checkProductInStockByStockmessage = (stockMessage?: string) =>
  !!stockMessage && !NOT_IN_STOCK_STOCKMESSAGES.includes(stockMessage);

export const getOrderableProducts = (products?: Product[] | null) =>
  products?.filter((product) => product?.atp?.stockMessage !== ATP_MESSAGES.TEMP_NO_STOCK);

// TODO (17/03/2022): Update productLink and other components
// to not depend on solr product structure
export const remapObjectToSupportedFormat = (brandName: string, name: string, url: string) => ({
  brand: {
    name: brandName,
  },
  name,
  url,
});

export const canBeAddedToCart = (product: Product) =>
  product?.canBeSold && product?.atp?.addToCartMessage === ATP_MESSAGES.BUTTON_STOCK;

export const getDiscountAmount = (product: Product) =>
  product.strikePrice?.value && product.price?.value ? product.strikePrice.value - product.price.value : null;

/**
 * Sorts pickup products to the end of the array
 */
export const sortPickupProductsAtEnd = (a: Product, b: Product): number => {
  const specificStockMessage = [PRODUCT_STOCK_MESSAGE.PICKUP_K, PRODUCT_STOCK_MESSAGE.PICKUP_T];

  if (!a?.atp?.stockMessage || !b?.atp?.stockMessage) {
    return 0;
  }

  if (specificStockMessage.includes(a?.atp?.stockMessage) && !specificStockMessage.includes(b?.atp?.stockMessage)) {
    return 1;
  }

  if (!specificStockMessage.includes(a?.atp?.stockMessage) && specificStockMessage.includes(b?.atp?.stockMessage)) {
    return -1;
  }

  return 0;
};

/**
 * Return product slider
 */
export const getProductsForProductsSlider = (
  products: Product[],
  showOutOfStockProducts: boolean,
  showPickupProductsAtEnd: boolean,
): Product[] => {
  const productsWithApproval = (!!products?.length && products?.filter((product) => product?.code)) || [];

  const atpMessagesProductsInStock = [PRODUCT_STOCK_MESSAGE.STOCK, ATP_MESSAGES.BUTTON_STOCK];

  const sliderProductsOutOfStock = showOutOfStockProducts
    ? productsWithApproval
    : productsWithApproval?.filter((product) =>
        Object.values(product?.atp ?? {}).find((atpMessage) => atpMessagesProductsInStock.includes(atpMessage)),
      );

  const sliderProducts = showPickupProductsAtEnd
    ? [...sliderProductsOutOfStock].sort(sortPickupProductsAtEnd)
    : sliderProductsOutOfStock;

  return sliderProducts;
};

export const isPartnerProduct = (product: Product) =>
  product?.partners?.[0]?.code?.toLowerCase() !== PRODUCT_PARTNERS.DEFAULT.toLowerCase();
